<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <BreadcrumbsComponent title="Темы общественного контроля" />
        <section class="section section-pb48 srv-catalog">
          <h2>Категории и проблематики</h2>
          <div class="srv-catalog__content">
            <div class="srv-catalog__categories" v-if="scCategories && scCategories.length">
              <div class="srv-catalog__categories-container">
                <div class="srv-catalog__wrap-container srv-catalog__wrap-container--parent">
                  <ul class="srv-catalog__wrap">
                    <li v-for="(item, index) in scCategories" :key="index">
                      <a
                        @click.prevent="showThemes(item.id)"
                        :class="{ active: isCategoryActive(item.id, true) }"
                        class="srv-catalog__item link"
                        href="#"
                      >
                        <i class="srv-catalog__icon icon" v-if="item.icon" v-html="item.icon"> </i>
                        <span>{{ item.title }}</span>
                        <i class="icon__right">
                          <ArrowRightIcon />
                        </i>
                      </a>
                    </li>
                  </ul>
                  <Multiselect
                    track-by="id"
                    label="title"
                    class="srv-catalog__ms"
                    :value="selectedCategory"
                    :options="scCategories"
                    @input="showThemes($event.id)"
                    placeholder="Выберите из списка"
                    selectLabel="Выбрать ↵"
                    deselectLabel="Удалить ↵"
                    selectedLabel="Выбрано"
                    :searchable="true"
                    :allow-empty="false"
                  >
                    <div class="srv-catalog__ms-label" slot="singleLabel" v-if="selectedCategory">
                      <component
                        :is="selectedCategory.icon"
                        class="srv-catalog__icon"
                        v-if="selectedCategory.id === -1"
                      />
                      <i
                        class="srv-catalog__ms-icon"
                        v-else-if="selectedCategory.icon"
                        v-html="selectedCategory.icon"
                      ></i>
                      <span class="srv-catalog__ms-title">{{ selectedCategory.title }}</span>
                    </div>
                    <span slot="noOptions">Список пуст</span>
                  </Multiselect>
                </div>
              </div>
              <ul class="srv-catalog__wrap" v-if="selectedThemes && selectedThemes.length">
                <li v-for="(item, index) in selectedThemes" :key="index">
                  <div class="srv-catalog__link sc-themes__item link">
                    <div class="child-item__title" v-if="item.title">{{ item.title }}</div>
                    <div class="srv-catalog__title" v-if="item.description">{{ item.description }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <p v-else class="_item__text">Тем нет</p>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ArrowRightIcon from "@/components/svg/ArrowRightSmall.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "SocialControlThemes",
  async asyncData({ store }) {
    await store.dispatch("GET_SC_THEMES");
  },
  data() {
    return {
      selectedCategory: null,
      childCategories: [],
      selectedThemes: [],
    };
  },
  mounted() {
    if (this.scCategories && this.scCategories.length) {
      this.showThemes(this.scCategories[0].id);
    }
  },
  computed: {
    scCategories() {
      return this.$store.state.sc_categories;
    },
  },
  methods: {
    showThemes(id) {
      this.selectedCategory = this.scCategories.find((item) => item.id === id);
      if (this.selectedCategory.child_categories) {
        this.selectedThemes = this.selectedCategory.child_categories;
      }
    },
    isCategoryActive(id) {
      if (this.selectedCategory) {
        return id === this.selectedCategory.id;
      }
      return false;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  components: {
    Multiselect,
    BreadcrumbsComponent,
    ArrowRightIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/services/services_catalog.styl"
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
